import axios from "axios"
import AvailableScheduleTag from "packs/models/AvailableScheduleTag"
import Util from "packs/utils/Util"
import { DateTime } from "luxon"
import Notice from "packs/models/Notice"
import Room from "packs/models/Room"
import RoomMember from "packs/models/RoomMember"
import Appointment from "packs/models/Appointment"
import ActionCable from "actioncable"
import { funcName, Logger } from "packs/common"
import RoomMessage from "packs/models/RoomMessage"

import RoomStorage from "packs/models/RoomStorage"
import PossibleDate from "packs/models/PossibleDate"
import SubscriptionManager from "packs/models/SubscriptionManager"
import NotificationControl from "packs/utils/NotificationControl"
import PossibleDatesManager from "packs/models/PossibleDatesManager"
import Refresher from "packs/models/Refresher"
import LocationTag from "packs/models/LocationTag"
import FormField from "./FormField"
import CalendarTag from "./CalendarTag"
import MeetingAttendeesGroup from "./MeetingAttendeesGroup"
import ChukaiFetcher from "./fetcher/ChukaiFetcher"
const roomStorage = new RoomStorage()

interface VotedRoomManager {
    publicRoom: Room
    rooms?: Room[]
    userInfo: RoomMember
    pds: PossibleDate[]
    suggestedMembers: RoomMember[]
    notSuggestedMembers: RoomMember[]
    maxAttendeesNum: number
    numberList: { key: number; value: number } // key: start_time, value: number of attendees
    suggestedDates: PossibleDate[]
    displayFields: FormField[]
    loading: boolean
    myParentRoom: Room
    myAstag: AvailableScheduleTag
    fixVoteType: string
    attendees: RoomMember[]
    notAttendees: RoomMember[]

    // 複製同期時メニューで利用します.
    openMember: RoomMember
    suggested_dates_display_type: string
    suggested_dates_display_start_at: number
    suggested_dates_display_end_at: number
    suggested_dates_optional_schedules: number[]
    sendPublicRooms: Room[]

    // 別の公開ページで投票で確定した日程（確定済みの日程）がわかるように、公開ページで確定した日程を保持します.
    // 確定したらデータを取得し直すので、ここには保持しません.
    astagFixTimeDic: any
    // 日付ごとの提案件数を保持します.
    dateListDic: any
    isFiltered: boolean
    filteredDates: PossibleDate[]
    didConflictCheck: boolean

    createNew(room: Room, rooms: Room[], userInfo: RoomMember)
    getAllMembers(): RoomMember[]
    updatePdList(pds: PossibleDate[])
    getRoomFromMember(rooms: Room[], mem: RoomMember): Room
    resetManager()
    saveVotedRooms(...args): Promise<any>
    updateDisplayFields(fs: FormField[], room: Room)
    saveDisplayFieldsInPublicRoom(fs: FormField[], room: Room)
    getDisplayFieldsInPublicRoom(roomId: string)
    getMembersAttendeeOrNot(pd: PossibleDate, needSave: boolean): any[]
    createOtherRoomsFromChukai()
    updatePublicRoomsAstagFromChukai()
    resetChukaiMenu()
    updateRoomMember(mem: RoomMember)
    conflictCheck(): Promise<PossibleDate[]>
}

//  データストア
let _VotedRoomManager: VotedRoomManager = {
    publicRoom: null,
    rooms: null,
    userInfo: null,
    pds: null,
    suggestedMembers: null,
    notSuggestedMembers: null,
    maxAttendeesNum: -1,
    numberList: null,
    suggestedDates: null,
    displayFields: [], // FormFieldをarrayで保持（param_keyで取得）
    loading: false,
    // 投票時に自分の
    myParentRoom: null,
    // 自分が投票した日程をもつAvailableScheduleTag
    myAstag: null,
    fixVoteType: `attendees_only`,

    // 出席者と非出席者を保持しておきます.
    attendees: null,
    notAttendees: null,

    // 複製同期時メニューで利用します.
    openMember: null,
    suggested_dates_display_type: `all`, // all, period, select_self
    suggested_dates_display_start_at: null, // periodの場合の開始日
    suggested_dates_display_end_at: null, // periodの場合の終了日
    suggested_dates_optional_schedules: null, // select_selfの場合の候補日程
    sendPublicRooms: [],

    astagFixTimeDic: {}, // {astag_id: Array[fix_start_time<number>]}
    dateListDic: {}, // {`0106`: {start_time: 0106, timeNum: 3, timeArr: [pd.start_time]}}
    isFiltered: false,
    filteredDates: null,
    didConflictCheck: false,

    /**
     *
     * @param room 公開ページ
     * @param rooms 公開ページに紐づく限定公開ページ
     */
    createNew(room: Room, rooms: Room[], userInfo: RoomMember) {
        Logger(`vrm.${funcName()} room.id${room.id}`)
        this.publicRoom = room
        this.userInfo = userInfo
        let datesSummary = room.rooms_voted_summary || {}
        let totalSuggestedDates: PossibleDate[] = datesSummary.suggested_dates || []
        Logger(`vrm.${funcName()} totalSuggestedDates:${Util.output(totalSuggestedDates)}`)
        // 未確定の調整ルーム一覧.
        // this.rooms = [...rooms]
        let _rooms = [...(room.rooms_voted || [])]
        let _not_voted_rooms = [...(room.rooms_not_voted || [])]
        Array.prototype.push.apply(_rooms, _not_voted_rooms)

        let allmems = []
        let opMems = []
        let obs = []
        let notSuggestedMems = []
        let pds = []
        for (let room of _rooms) {
            let mems = room.members.filter(mem => mem.role != 100)
            // Array.prototype.push.apply(allmems, mems)
            for (let _mem of mems) {
                if (Util.isPresent(_mem.suggested_dates)) {
                    Logger(
                        `vrm.${funcName()} suggested_dates[${_mem.suggested_dates.length}]: ${Util.output(
                            _mem.suggested_dates
                        )}, mem: ${Util.output(_mem)}`
                    )
                    Array.prototype.push.apply(pds, _mem.suggested_dates)
                    _mem.priorityType = room.priority_type
                    if (_mem.role == RoomMember.MEMBER) {
                        allmems.push(_mem)
                    } else if (_mem.role == RoomMember.MEMBER_OPTIONAL) {
                        opMems.push(_mem)
                    } else if (_mem.role == RoomMember.OBSERVER) {
                        obs.push(_mem)
                    }
                } else {
                    notSuggestedMems.push(_mem)
                }
            }
        }
        // 並び順を考慮するため、それぞれのroleに分離した後マージする.
        Array.prototype.push.apply(allmems, opMems)

        // 全メンバー.
        this.suggestedMembers = allmems
        this.notSuggestedMembers = notSuggestedMems
        this.rooms = _rooms
        Logger(`vrm.${funcName()} suggestedMembers: ${Util.output(this.suggestedMembers)}`)

        this.updatePdList(totalSuggestedDates)
    },
    getAllMembers() {
        let mems = [...this.suggestedMembers]
        let notSugmems = [...this.notSuggestedMembers]
        Array.prototype.push.apply(mems, notSugmems)
        return mems
    },
    // メンバーに更新があったら、表示するpdや数を更新します.
    /**
     *
     * @param pds 投票したメンバーの日程候補
     * @param sds 全てのメンバーの予定+オーナーのコンフリクト情報.
     */
    updatePdList(pds: PossibleDate[]) {
        Logger(`vrm.${funcName()} pds:${Util.output(pds)}`)

        let currentPds = (pds || []).sort((a, b) => a.start_time - b.start_time)
        // 同一時間が入らないようにフィルタリングします.
        let startTimes = []
        let numList = {}
        let filteredPds = []
        let dateListDic = {} // {`0106`: {start_time: 0106, timeNum: 3}}
        for (let _pd of currentPds) {
            let datetimeStr = _pd.short_format // 01120900
            let date = datetimeStr.slice(0, 4) // 0112
            if (Object.keys(dateListDic).includes(date)) {
                if (!dateListDic[date].timeArr.includes(_pd.start_time)) {
                    dateListDic[date].timeNum += 1
                    dateListDic[date].timeArr.push(_pd.start_time)
                }
            } else {
                dateListDic[date] = { start_time: _pd.start_time, timeNum: 1, timeArr: [_pd.start_time] }
            }

            if (startTimes.includes(_pd.start_time)) continue

            let _num = this.suggestedMembers.filter(mem => mem.suggested_dates.find(sd => sd.start_time == _pd.start_time)).length
            // numList.push(_num)
            numList[_pd.start_time] = _num

            // sdsをもらっている場合は、sdsに含まれるconflict_infoをpdに移して利用します.
            // if (sds) {
            //     let sd = (sds || []).find(sd => sd.start_time == _pd.start_time)
            //     if (sd) {
            //         _pd.conflict_info = sd.conflict_info
            //     } else {
            //         _pd.conflict_info = null
            //     }
            // }
            startTimes.push(_pd.start_time)
            filteredPds.push(_pd)
        }
        this.maxAttendeesNum = Math.max(...Object.values(numList).map(n => Number(n)))
        this.numberList = numList
        Logger(`vrm.updatePdList  suggestedDates:${Util.output(filteredPds)}, dateListDic:${Util.output(dateListDic)}`)
        this.dateListDic = dateListDic

        this.suggestedDates = filteredPds
    },
    getRoomFromMember(rooms: Room[], mem: RoomMember): Room {
        for (let _room of rooms) {
            let _mem = _room.members.find(m => m.user_id == mem.user_id && m.id == mem.id)
            if (Util.isPresent(_mem)) {
                return _room
                break
            }
        }
    },
    resetManager() {
        Logger(`vrm.${funcName()}`)
        this.publicRoom = null
        this.rooms = null
        this.pds = null
        this.suggestedMembers = null
        this.maxAttendeesNum = -1
        this.numberList = null
        this.suggestedDates = null
        this.displayFields = []
        this.didConflictCheck = false
    },
    saveVotedRooms(
        pd: PossibleDate,
        text: string,
        attendees: RoomMember[],
        textMemo: string,
        isSameMailAndMemo: boolean,
        locationName: string,
        zoomAccs: CalendarTag[],
        forceType: string = null,
        force: boolean = false,
        mag: MeetingAttendeesGroup = null,
        fAppoDic: any = null
    ): Promise<any> {
        let parentRoomIds = []
        let sampleId = null
        let mems = this.suggestedMembers.filter(mem => mem.suggested_dates.find(sd => sd.start_time == pd.start_time))
        let newAttendees = []
        if (this.fixVoteType == `all`) {
            // 全員 all
            parentRoomIds = this.rooms.map(p => p.id)
            let room = this.getRoomFromMember(this.rooms, mems[0])
            if (room) sampleId = room.id
        } else {
            // 出席者のみ attendees_only
            Logger(`vrm.${funcName()} 指定されたメンバーのみを確定: ${attendees.length}人 ${attendees.map(att => att.name)}`)
            // この日時で出席可能と言っているユーザーID
            const attendableUserIds = mems.map(m => m.user_id)

            for (let mem of attendees) {
                let room = this.getRoomFromMember(this.rooms, mem)
                if (room) {
                    parentRoomIds.push(room.id)
                } else {
                    // 調整ページがないため、新規で調整ページを作成し、確定するメンバーです.
                    mem = RoomMember.setAnswerResultsFromMember(mem)
                    newAttendees.push(mem)
                }

                if (!sampleId && attendableUserIds.includes(mem.user_id)) {
                    // この日に参加できる出席者のルームIDです.
                    sampleId = room.id
                    Logger(`vrm.${funcName()} sampleに利用するメンバー: ${mem.name}`)
                }
            }
        }
        // TODO: selected_users（グレイアウトさせていないユーザーに送る）
        if (Util.isPresent(mag)) {
            Logger(`vrm.${funcName()} magを指定しているため、強制確定に変更します.`)
            force = true
        }

        // let force = false
        if (
            Util.isBlank(sampleId) &&
            confirm(`出席可能な出席者がいませんが強制的に確定しますか？オーナーの予定も無視します。`) &&
            !force
        ) {
            force = true
        }
        if (
            !force &&
            Util.isPresent(forceType) &&
            forceType == `filled` &&
            confirm(`予定が埋まっていますが、無視して強制的に確定しますか？`)
        ) {
            force = true
        }

        if (this.loading) return Promise.resolve(null)
        this.loading = true

        if (isSameMailAndMemo) {
            textMemo = text
        }
        Logger(`vrm.saveVotedRooms myParentRoom:${this.myParentRoom?.id}`)

        return Appointment.fixVotedRoom(
            this.publicRoom,
            pd,
            this.fixVoteType,
            parentRoomIds,
            sampleId,
            text,
            force,
            textMemo,
            locationName,
            zoomAccs,
            newAttendees,
            this.myParentRoom,
            this.userInfo,
            mag,
            fAppoDic
        ).then(res => {
            Logger(`vrm.fixVotedRoom res:${Util.output(res)}`)
            if (!res) {
                return null
            } else if (res.status == 400) {
                if (window.confirm(`予定が埋まっていて確定できませんでした。強制的に確定しますか？`)) {
                    this.loading = false
                    return this.saveVotedRooms(
                        pd,
                        text,
                        attendees,
                        textMemo,
                        isSameMailAndMemo,
                        locationName,
                        zoomAccs,
                        null,
                        true,
                        mag,
                        fAppoDic
                    ).then(res => {
                        return res
                    })
                } else {
                    this.loading = false
                }
            } else {
                this.loading = false
                const appo = res.appo as Appointment
                const roomIds = res.room_ids as string[]
                const newParents = res.new_parent_rooms as Room[]
                const fixed_astags = res.fixed_astags as string[]
                // room_idsがあったら、parent_roomの情報を更新してfixed_roomsに移動
                let _rooms = [...this.rooms]
                let fixedRooms = []
                let notFixedRooms = []
                let updatedMemberIds = fAppoDic?.update_member_ids || []
                Logger(`vrm.saveVotedRooms updatedMemberIds:${updatedMemberIds} _rooms:${_rooms?.length}`)
                for (let _room of _rooms) {
                    if (roomIds.includes(_room.id)) {
                        _room.current_appointment = Appointment.copy(appo)
                        _room.status = Room.STATUS_ACTIVE_UPCOMING_FIXED
                        _room.status_name = `確定済`
                        if (roomIds.length > 1) {
                            _room.is_voted = true
                        }
                        let _mems = _room.members || []
                        let _mem = _mems.find(mem => mem.role != RoomMember.OWNER)
                        Logger(`vrm.saveVotedRooms _mem.id:${_mem.id}`)
                        if (updatedMemberIds.includes(_mem.id)) {
                            let _updatedMem = fAppoDic.f_members.find(mem => mem.id == _mem.id)
                            if (_updatedMem) {
                                _mem.name = _updatedMem.name
                                RoomMember.updateLocalAnswerResults(_mem, `attendee1_name`, _updatedMem.name)
                            }
                        }
                        let updatedAppoName = (fAppoDic?.f_appo || {})?.name
                        if (updatedAppoName) {
                            if (_room?.current_appointment) _room.current_appointment.name = updatedAppoName
                            if (_room?.appeal_content) _room.appeal_content.title = updatedAppoName
                        }
                        fixedRooms.push(_room)
                    } else {
                        notFixedRooms.push(_room)
                    }
                }
                if (Util.isPresent(newParents)) {
                    Logger(`vrm.${funcName()} 新たに作成された調整ページを投票確定に変更します.`)
                    for (let _newRoom of newParents) {
                        _newRoom.current_appointment = Appointment.copy(appo)
                        _newRoom.status = Room.STATUS_ACTIVE_UPCOMING_FIXED
                        _newRoom.status_name = `確定済`
                        if (roomIds.length > 1) {
                            _newRoom.is_voted = true
                        }
                        fixedRooms.push(_newRoom)
                    }
                }
                // 確定したmemberを投票ページから抜きます.
                let _mems = [...(this.suggestedMembers || [])]
                let suggestedMembers = []
                let pds = []
                let today = DateTime.local().toSeconds()
                for (let _mem of _mems) {
                    let room = this.getRoomFromMember(this.rooms, _mem)

                    if (updatedMemberIds.includes(_mem.id)) {
                        let _updatedMem = fAppoDic.f_members.find(mem => mem.id == _mem.id)
                        if (_updatedMem) {
                            _mem.name = _updatedMem.name
                            RoomMember.updateLocalAnswerResults(_mem, `attendee1_name`, _updatedMem.name)
                        }
                    }
                    // 確定したroom_idに含まれている場合そのメンバーをはずします.
                    // そのためピンしている投票ページはそのまま残ります.
                    if (!roomIds.includes(room.id)) {
                        let _suggestedDates = _mem.suggested_dates
                        let currentTime = _suggestedDates.find(
                            sd => sd.start_time == appo.start_time && fixed_astags.includes(_mem.available_schedule_tag_id)
                        )
                        if (currentTime) {
                            currentTime.conflict_info = PossibleDate.createDefaultConflictInfo()
                        }
                        suggestedMembers.push(_mem)
                        if (Util.isPresent(_suggestedDates)) {
                            Logger(`vrm.suggested_dates: ${Util.output(_suggestedDates)}`)

                            let sds = _suggestedDates.filter(sd => sd.start_time > today)
                            Array.prototype.push.apply(pds, sds)
                        }
                    }
                }
                for (let astagId of res.fixed_astags) {
                    this.astagFixTimeDic[astagId] ||= []
                    this.astagFixTimeDic[astagId].push(appo.start_time)
                }

                this.rooms = notFixedRooms
                this.suggestedMembers = suggestedMembers
                if (Util.isPresent(this.myParentRoom) && roomIds.includes(this.myParentRoom.id)) {
                    this.myParentRoom.current_appointment = appo
                    if (roomIds.length > 1) {
                        this.myParentRoom.is_voted = true
                    }
                }
                this.updatePdList(pds)
                Logger(
                    `vrm.${funcName()} 確定したアポを取得しました。${res?.appo?.name}, astagFixTimeDic:${Util.output(
                        this.astagFixTimeDic
                    )}`
                )
                return [fixedRooms, notFixedRooms]
            }
        })
    },
    updateDisplayFields(fs: FormField[], room: Room) {
        this.displayFields = fs
        this.saveDisplayFieldsInPublicRoom(fs, room)
    },
    saveDisplayFieldsInPublicRoom(fs: FormField[], room: Room) {
        // 固定表示している質問項目をページ遷移しても消えないようにします.
        let roomStorage = new RoomStorage()
        roomStorage.saveDisplayFieldsInPublicRoom(fs, room.id)
    },
    getDisplayFieldsInPublicRoom(roomId: string) {
        let roomStorage = new RoomStorage()
        let fields: FormField[] = roomStorage.fetchDisplayFieldsInPublicRoom(roomId)
        return fields
    },
    getMembersAttendeeOrNot(pd: PossibleDate, needSave: boolean = false) {
        // 出席可能なメンバーと不可のメンバーに分けます.
        // 参加できるメンバー・できないメンバーをアップデート
        let allmems = [...(this.getAllMembers() || [])]
        let attendees = []
        let notAttendees = []
        for (let mem of allmems) {
            if ((mem.suggested_dates || []).find(sd => sd.start_time == pd.start_time)) {
                // 出席可能なメンバーです.
                mem.attendable = true
                attendees.push(mem)
            } else {
                mem.attendable = false
                notAttendees.push(mem)
            }
        }
        if (needSave) {
            this.attendees = attendees
            this.notAttendees = notAttendees
        }
        Logger(
            `getMembersAttendeeOrNot attendees mem.id:${Util.output(attendees.map(att => att.id))}, mem.user_id:${Util.output(
                attendees.map(att => att.user_id)
            )}`
        )
        return [attendees, notAttendees]
    },
    createOtherRoomsFromChukai() {
        let selectedRoomIds = this.sendPublicRooms.map(room => room.id)
        Logger(`vrm.${funcName()} selectedRooms:${Util.output(selectedRoomIds)}`)
        let params = {
            room_ids: selectedRoomIds,
            member: this.openMember,
            suggested_dates_display_type: this.suggested_dates_display_type,
            suggested_dates_display_start_at: this.suggested_dates_display_start_at,
            suggested_dates_display_end_at: this.suggested_dates_display_end_at,
            suggested_dates_optional_schedules: this.openMember.suggested_dates_optional_schedules,
        }
        return ChukaiFetcher.createOtherRoomsFromChukai(params).then(res => {
            if (res) {
                // 成功したら、メンバーをアップデートします.
                // let currentMem = this.suggestedMembers.find(m => m.id == this.openMember.id)
                // if (currentMem) {
                //     currentMem.suggested_dates_display_type = this.suggested_dates_display_type
                //     currentMem.suggested_dates_display_start_at = this.suggested_dates_display_start_at
                //     currentMem.suggested_dates_display_end_at = this.suggested_dates_display_end_at
                //     currentMem.suggested_dates_optional_schedules = this.openMember.suggested_dates_optional_schedules
                // }

                return res
            }
        })
    },
    // 公開ページに調整カレンダーを設定します.
    updatePublicRoomsAstagFromChukai() {
        let selectedRoomIds = this.sendPublicRooms.map(room => room.id)
        Logger(`vrm.${funcName()} selectedRooms:${Util.output(selectedRoomIds)}`)
        let params = {
            room_ids: selectedRoomIds,
            member: this.openMember,
            suggested_dates_display_type: this.suggested_dates_display_type,
            suggested_dates_display_start_at: this.suggested_dates_display_start_at,
            suggested_dates_display_end_at: this.suggested_dates_display_end_at,
            suggested_dates_optional_schedules: this.suggested_dates_optional_schedules,
        }
        return ChukaiFetcher.updatePublicRoomsAstagFromChukai(params).then(res => {
            if (res) {
                // 成功したら、メンバーをアップデートします.
                // let currentMem = this.suggestedMembers.find(m => m.id == this.openMember.id)
                // if (currentMem) {
                //     currentMem.suggested_dates_display_type = this.suggested_dates_display_type
                //     currentMem.suggested_dates_display_start_at = this.suggested_dates_display_start_at
                //     currentMem.suggested_dates_display_end_at = this.suggested_dates_display_end_at
                //     currentMem.suggested_dates_optional_schedules = this.openMember.suggested_dates_optional_schedules
                // }
                return res
            }
        })
    },
    resetChukaiMenu() {
        this.openMember = null
        this.suggested_dates_display_type = `all` // all, period, select_self
        this.suggested_dates_display_start_at = null // periodの場合の開始日
        this.suggested_dates_display_end_at = null // periodの場合の終了日
        this.suggested_dates_optional_schedules = null // select_selfの場合の候補日程
        this.sendPublicRooms = []
    },
    updateRoomMember(mem: RoomMember) {
        Logger(`vrm.updateRoomMember mem:${mem.id} ${mem.name} ${mem.private_memo} ${mem.systemUpdatedAt}`)
        /**
         * 名前の変更やメモの変更があった場合に、投票メンバーをアップデートします.
         */
        let _mem = (this.suggestedMembers || []).find(m => m.id == mem.id)
        if (_mem) {
            _mem.name = mem.name
            _mem.private_memo = mem.private_memo

            _mem.systemUpdatedAt = Util.getSec()

            Logger(`vrm.updateRoomMember mem2:${_mem.systemUpdatedAt}`)
        }
    },

    conflictCheck(): Promise<PossibleDate[]> {
        let params = {
            ok_schedules: this.suggestedDates,
            id: this.publicRoom.id,
        }
        return Room.conflictCheck(this.publicRoom, params).then(res => {
            Logger(`vrm.conflictCheck res:${Util.output(res)}`)
            if (res) {
                this.suggestedDates = res.ok_schedules
                this.didConflictCheck = true
                return res.ok_schedules
            }
        })
    },
}
export default _VotedRoomManager
